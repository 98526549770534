import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import styled from "@emotion/styled"
import TopicsSection from "../components/topics/topics-section"
import { useState } from "react"
import { UsePageContext } from "../context/page-context"
/*import getSiteData from "../components/topics/topics-get-query"*/

const Root = styled.div`
  padding: 40px 0 calc(80px - 1.45rem) 0;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Top = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  @media screen and (min-width: 712px) {
    align-items: flex-start;
    width: 680px;
  }
`

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  border-radius: 4px;
`

const Select = styled.select`
  display: flex;
  align-items: center;
  padding: 0 12px 0 16px;
  box-sizing: border-box;
  height: 36px;
  width: 215px;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 4px;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 14px;
`

const PlaceHolder = styled.option`
  height: 14px;
  width: 122px;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 14px;
`

const Option = styled.option`
  height: 14px;
  width: 122px;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 14px;
`

const Topics = ({ data }) => {
  const { setIsAtContactPage } = UsePageContext()
  useState(() => {
    setIsAtContactPage(false)
  })

  const topicData = data.allContentfulTopic.edges
  const topicsTags = data.allContentfulTopic.distinct
  const [selectedTags, setTag] = useState(null)

  const changeTag = event => {
    const input = event.target.value
    setTag(input)
  }
  const noneSlug = ""

  return (
    <>
      <Seo title="トピックス" description="株式会社アクアのトピックスです。" />
      <Root>
        <Container>
          <Top>
            <Title>トピックス</Title>
            <Select onChange={event => changeTag(event)}>
              <PlaceHolder value="">カテゴリー</PlaceHolder>
              {topicsTags.map((tags, index) => {
                return (
                  <Option key={index} value={tags}>
                    {tags}
                  </Option>
                )
              })}
            </Select>
          </Top>
          <TopicsSection data={topicData} tag={selectedTags} slug={noneSlug} />
        </Container>
      </Root>
    </>
  )
}
/*<TopicsSection topics={TopicData}/> */
export const query = graphql`
  query TopicQuery {
    allContentfulTopic(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          contentful_id
          title
          slug
          topimage {
            gatsbyImageData(placeholder: BLURRED)
          }
          createdAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
          updatedAt(locale: "ja-JP")
          author
          bodytext {
            childMarkdownRemark {
              html
            }
          }
          category {
            name
            contentful_id
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      distinct(field: category___name)
    }
  }
`

export default Topics
